import send from "./icons/send.svg"
import collapse from "./icons/collapse.svg"
import logout from "./icons/logout.svg"
import golive from "./icons/golive.svg"
import bubble from "./icons/bubble.svg"
import play from "./icons/play.svg"
import createvideo from "./icons/createvideo.svg"
import logo from "./icons/logo.svg"
import home from "./icons/home.svg"

const tick = "https://www.rooter.gg/svg/common/profileVerifyFilled.svg";
const streamer = "https://www.svgrepo.com/show/421065/game-gamepad-gaming.svg";
const followers = "https://loco.gg/topStreamers/followersIcon.svg";
const eye = "https://loco.gg/topStreamers/viewsIcon.svg";
const userplus = "https://loco.gg/topStreamers/userPlus.svg";

export {home,tick,send,streamer,followers,eye,userplus,collapse,logout,golive,bubble,logo,play,createvideo};